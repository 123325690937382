/* eslint-disable @next/next/no-img-element */
import React, { useState } from "react";
import Link from "next/link";
import { collection_data } from "../../data/collection_data";
import HeadLine from "../headLine";
import Image from "next/image";

const Top_collection = () => {
  const [timeActiveText, setTimeActiveText] = useState("last 7 days");
  const [data, setData] = useState(collection_data);
  const [dropdownShow, setDropdownShow] = useState(false);
  const timeText = [
    {
      id: 1,
      text: "Last 24 Hours",
    },
    {
      id: 2,
      text: "Last 7 days",
    },
    {
      id: 3,
      text: "Last 30 days",
    },
  ];

  const handleFilter = (text) => {
    setTimeActiveText(text);
    const newCollectionData = collection_data.filter((item) => {
      if (text === "Last 30 days") {
        return item;
      }
      return item.postDate === text;
    });
    setData(newCollectionData);
  };

  const handleDropdown = (e) => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-toggle")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };

  return (
    <div>
      <section className="dark:bg-jacarta-800 relative py-24">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
        <div className="container">
          <div className="font-display text-jacarta-700 mb-12 text-center text-lg sm:text-3xl dark:text-white flex justify-center items-center gap-x-3">
            <HeadLine text="Upcoming Projects 👍" classes="inline" />

           
          </div>
          <div className="font-display text-jacarta-700 mb-12 text-center text-lg sm:text-2xl dark:text-red flex justify-center items-center gap-x-3">
            <HeadLine text="List your project (WIP)" classes="inline" />

           
          </div>
        
       
        </div>
      </section>
    </div>
  );
};

export default Top_collection;
